import * as styles from './Portfolio.module.scss'
import React, { useState, useCallback } from 'react'

import { useWindowSize } from 'utils'

import PortfolioList from './PortfolioList'
import Filters from 'components/Filters'
import Contact from 'components/Contact'

import siteConfig from 'site/settings/site_config.json'
import contact_settings from 'site/settings/contact_settings.json'
import portfolioTags from 'site/portfolio/portfolio_tags.json'

const defaultTag = siteConfig.portfolio_main_filter_tag || 'All works'

const Portfolio = ({ portfolioItems }) => {
  const [filterTag, setFilterTag] = useState(defaultTag)

  const [windowWidth] = useWindowSize()

  const onChangeFilterTag = useCallback(tag => setFilterTag(tag), [])

  return (
    <div className="container">
      <Filters
        isMobile={windowWidth <= 767}
        filterTagsArray={portfolioTags.portfolio_tag}
        defaultTag={defaultTag}
        filterTag={filterTag}
        onChangeFilterTag={onChangeFilterTag}
      />
      <PortfolioList
        defaultTag={defaultTag}
        portfolioItems={portfolioItems}
        filterTag={filterTag}
      />
      <Contact
        className={styles.contact}
        title={contact_settings.title}
        subTitle={siteConfig.portfolio_contact_description}
      />
    </div>
  )
}

export default Portfolio
