import React, { useState, useEffect } from 'react'
import { useDelayUnmount } from 'utils'

import * as styles from './TagsMob.module.scss'
import cn from 'classnames'

import { Portal } from 'ui'
import Tags from '../Tags'
import ToggleButton from './ToggleButton'

const TagsMob = ({ onChangeFilterTag, ...props }) => {
  const [openMobMenu, setOpenMobMenu] = useState(false)

  const handleToggleMobMenu = () => setOpenMobMenu(prev => !prev)
  const handleCloseMobMenu = () => setOpenMobMenu(false)

  const shouldRenderMenu = useDelayUnmount(openMobMenu, 100)

  return (
    <>
      {openMobMenu && (
        <Portal wrapperId="react-portal-TagsMob-overlay">
          <div className={styles.overlay} onClick={handleCloseMobMenu}></div>
        </Portal>
      )}
      <div className={styles.container}>
        <ToggleButton
          filterTag={props.filterTag}
          isActive={openMobMenu}
          onClick={handleToggleMobMenu}
        />
        {shouldRenderMenu && (
          <Tags
            {...props}
            onChangeFilterTag={tag => {
              onChangeFilterTag(tag)
              handleCloseMobMenu()
            }}
            className={cn(
              styles.container__tags,
              openMobMenu ? styles.fadeIn : styles.fadeOut,
            )}
            itemClassName={cn(
              styles.container__tags__item,
              props.itemClassName,
            )}
            buttonClassName={styles.container__tags__item__button}
          />
        )}
      </div>
    </>
  )
}

export default TagsMob
