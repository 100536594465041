import React from 'react'
import * as styles from './Tags.module.scss'
import cn from 'classnames'

import { DefaultButton as Button } from 'ui'

const Tags = ({
  defaultTag,
  filterTag,
  filterTagsArray,
  onChangeFilterTag,
  className,
  itemClassName,
  buttonClassName,
}) => {
  return (
    <ul className={cn(styles.list, className)}>
      <li
        className={cn(styles.list__item, itemClassName)}
        key="filters-item-main">
        <Button
          title={defaultTag}
          value={defaultTag}
          variant="light"
          type="radio"
          name="filters-radio-buttons"
          id="filters-radio-buttons-button-all_works"
          checked={filterTag === defaultTag}
          onChange={() => onChangeFilterTag(defaultTag)}
          className={buttonClassName}
        />
      </li>

      {filterTagsArray.map(({ name }, i) => (
        <li
          className={cn(styles.list__item, itemClassName)}
          key={`filters-item-${i}`}>
          <Button
            title={name}
            value={name}
            variant="light"
            type="radio"
            name="filters-radio-buttons"
            id={`filters-radio-buttons-button-${i}`}
            checked={filterTag === name}
            onChange={() => onChangeFilterTag(name)}
            className={buttonClassName}
          />
        </li>
      ))}
    </ul>
  )
}

export default Tags
