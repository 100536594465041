import React, { useMemo } from 'react'
import * as styles from './PortfolioItem.module.scss'
import cn from 'classnames'

import { Link } from 'gatsby'

import { Title, SubTitle } from 'ui'
import { ImageFromPath } from 'utils'

const PortfolioItem = ({
  title,
  tags,
  industry,
  preview_image,
  linkTo,
  titleClass,
  subTitleClass,
}) => {
  const tagsString = useMemo(() => tags.join(', '), [tags])

  return (
    <li className={styles.card}>
      <Link to={linkTo} className={cn(styles.card__imageWrap, 'cardClipHover')}>
        <ImageFromPath url={preview_image?.publicURL} alt="Image" />
        <div className={styles.card__imageWrap__tags}>
          <span className={styles.card__imageWrap__tags__text}>
            {tagsString}
          </span>
        </div>
      </Link>
      <Link to={linkTo}>
        <Title
          text={title}
          variant="small"
          className={cn('text-center', styles.card__title, titleClass)}
        />
      </Link>
      <SubTitle
        text={industry}
        className={cn('text-center', styles.card__subtitle, subTitleClass)}
      />
    </li>
  )
}

export default PortfolioItem
