import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'components/layout'
import Portfolio from 'components/Portfolio'
import SEO from 'components/seo'
import siteConfig from 'site/settings/site_config.json'

const PortfolioPage = ({ location, data }) => {
  return (
    <Layout
      location={location}
      title={siteConfig.portfolio_title}
      subTitle={siteConfig.portfolio_description}>
      <SEO
        title={siteConfig.portfolio_meta_title}
        description={siteConfig.portfolio_meta_description || ''}
        location={location.href}
      />
      <Portfolio portfolioItems={data.allMarkdownRemark.nodes} />
    </Layout>
  )
}

export default PortfolioPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(portfolio)/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          preview_image {
            publicURL
          }
          main_image {
            publicURL
          }
          images_large {
            sort_index
            image {
              publicURL
            }
          }
          images_small {
            sort_index
            image {
              publicURL
            }
          }
          paragraphs {
            text
            title
          }
          tags_title
          tags
          working_time
          website_url
          website_name
          industry
          case_study_name
          case_study_url
          meta_title
          meta_description
        }
      }
    }
  }
`
