import React, { useState, useEffect } from 'react'
import * as styles from './PortfolioList.module.scss'

import { DefaultButton as Button } from 'ui'
import PortfolioItem from 'components/Portfolio/PortfolioItem'
import siteConfig from 'site/settings/site_config.json'

const paginationItemsPerPage = siteConfig.portfolio_items_per_page || 6

const PortfolioList = ({ portfolioItems, filterTag, defaultTag }) => {
  const [portfolios, setPortfolios] = useState([])
  const [page, setPage] = useState(1)

  const handleIncrementPage = () => setPage(prev => prev + 1)

  const onPaginate = page =>
    setPortfolios(portfolioItems.slice(0, page * paginationItemsPerPage))

  useEffect(() => onPaginate(page), [page])

  return (
    <>
      <ul className={styles.list}>
        {portfolios
          .filter(
            ({ frontmatter: { tags } }) =>
              filterTag === defaultTag || tags.includes(filterTag),
          )
          .map(({ frontmatter, fields: { slug } }) => (
            <PortfolioItem
              {...frontmatter}
              linkTo={`/portfolio${slug}`}
              key={slug}
            />
          ))}
      </ul>
      {portfolios.length !== portfolioItems.length && (
        <div className={styles.paginationContainer}>
          <Button
            type="button"
            variant="secondary"
            title="See More"
            onClick={handleIncrementPage}
          />
        </div>
      )}
    </>
  )
}

export default PortfolioList
