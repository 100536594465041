import React from 'react'

import * as styles from './ToggleButton.module.scss'
import cn from 'classnames'

import ChevronRight from 'components/Icons/ChevronRight'
import { DefaultButton as Button } from 'ui'

const ToggleButton = ({ filterTag, isActive, onClick }) => {
  return (
    <Button
      className={styles.button}
      title={
        <div
          className={styles.button__content}
          key={`ToggleButton-content-${filterTag}`}>
          <ChevronRight
            className={cn(styles.button__content__icon, {
              [styles.active]: isActive,
            })}
          />
          <span className={styles.button__content__text}>{filterTag}</span>
        </div>
      }
      variant="light"
      type="button"
      onClick={onClick}
    />
  )
}

export default ToggleButton
