import React, { memo, useState } from 'react'
import cn from 'classnames'

import * as styles from './Contact.module.scss'

import { Title, SubTitle, DefaultButton as Button } from 'ui'

import Modal from 'components/Modal'
import Form from 'components/FrontPage/FeedbackSection/Form/Form'

const Contact = memo(({ title, subTitle, className }) => {
  const [modalIsOpen, setmodalIsOpen] = useState(false)
  const handleOpenModal = () => setmodalIsOpen(true)
  const handleCloseModal = () => setmodalIsOpen(false)

  return (
    <>
      <div className={cn('container', styles.container, className)}>
        <div className="row">
          <div
            className={cn(
              'col-xxl-8 col-lg-8 col-md-12',
              styles.titlesContainer,
            )}>
            <Title text={title} />
            <SubTitle text={subTitle} />
          </div>
          <div className="col-xxl-4 col-lg-4 col-md-12 d-flex justify-content-center align-items-center">
            <div className={styles.buttonWrap}>
              <Button
                title="Let’s Talk"
                type="button"
                className={styles.button}
                onClick={handleOpenModal}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={modalIsOpen} onClose={handleCloseModal}>
        <Form isModal afterSubmit={handleCloseModal} />
      </Modal>
    </>
  )
})

export default Contact
