import React from 'react'

import TagsMob from './TagsMob'
import Tags from './Tags'

const Filters = ({ isMobile, ...props }) => {
  return isMobile ? <TagsMob {...props} /> : <Tags {...props} />
}

export default Filters
